import React, { useMemo } from "react";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { ApolloProvider } from "@apollo/client/react";

function ApiProvider() {
  const httpLink = useMemo(() => {
    return createHttpLink({
      uri: `${process.env.REACT_APP_API_URL}/graphql`,
    });
  }, []);

  const errorLink = useMemo(() => {
    return onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
      }
      if (networkError) {
      }
    });
  }, []);

  const client = useMemo(() => {
    return new ApolloClient({
      link: from([errorLink, httpLink]),
      cache: new InMemoryCache(),
      defaultOptions: {
        mutate: { errorPolicy: "ignore" },
      },
    });
  }, [errorLink, httpLink]);

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default ApiProvider;
