import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useIntl } from "react-intl";
import {
  CircularProgress,
  LinearProgress,
  Box,
  TextField,
  createTheme,
  ThemeProvider,
  styled,
} from "@mui/material";
import { theme } from "../../theme";
import { TAHKOCOM_EVENTS } from "../../api/api";
import { Grid, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { decode } from "html-entities";
import Button from "@mui/material/Button";
import fiLocale from "date-fns/locale/fi";
import EventIcon from "@mui/icons-material/Event";
import InputAdornment from "@mui/material/InputAdornment";

import ClearIcon from "@mui/icons-material/Clear";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import LinkButton from "../LinkButton";
import { grey } from "@mui/material/colors";

const eventgrid = {
  color: "white",
  textAlign: "center" as const,
  backgroundColor: theme.palette.primary.dark,
  margin: "16px",
  padding: "16px",
  borderRadius: "4px",
};

const eventimage = {
  height: "150px",
};

const darkTheme = createTheme({
  palette: {
    background: {
      default: "#1E2D4E",
      paper: "#1E2D4E",
    },
    primary: {
      main: "#B3C5E2",
    },
    text: {
      primary: "#fff",
      secondary: "#fff",
      disabled: grey[600],
    },
    action: {
      active: "#B3C5E2",
      disabled: "#B3C5E2",
    },
  },
});

type Category = {
  id: number;
  title: string;
  parentId: number;
};

type EventType = {
  title: string;
  contentshort: string;
  contentfull: string;
  imagesrc: string;
  dateStart: string;
  dateEnd: string;
  time: string;
  price: string;
  webpage: string;
  categories: Category[];
  location: string;
};

interface eventProps {
  event: EventType;
}

function Event({ event }: eventProps) {
  const intl = useIntl();
  const [showFullContent, setShowFullContent] = useState(false);

  const handleSetFullContent = () => {
    showFullContent ? setShowFullContent(false) : setShowFullContent(true);
  };

  return (
    <Grid
      onClick={() => handleSetFullContent()}
      style={eventgrid}
      item
      xs={12}
      sm={12}
      md={12}
    >
      <img style={eventimage} src={event.imagesrc} alt={event.contentshort} />
      <Typography pb={2} variant="h5">
        {decode(event.title)}
      </Typography>
      <Typography pb={2}>
        {new Date(event.dateStart).toLocaleDateString()}{" "}
        {event.dateEnd && event.dateEnd !== event.dateStart
          ? " - " + new Date(event.dateEnd).toLocaleDateString()
          : ""}{" "}
        {event.time ? " @ " + event.time : ""}
      </Typography>
      <Typography pb={2}>{event.price}</Typography>
      <Typography style={{ display: showFullContent ? "none" : "block" }}>
        {decode(event.contentshort)}
      </Typography>
      <Typography
        style={{
          display: showFullContent ? "block" : "none",
          textAlign: "left",
        }}
      >
        {event.contentfull.split("\n").map((r) => (
          <p>{decode(r)}</p>
        ))}
      </Typography>
      {event.webpage ? (
        <Typography
          style={{ display: showFullContent ? "block" : "none" }}
          pb={2}
        >
          <Button href={event.webpage}>
            {intl.formatMessage({ id: "towebsite" })} &#8594;
          </Button>
        </Typography>
      ) : (
        ""
      )}
      {event.location ? (
        <Typography
          style={{ display: showFullContent ? "block" : "none" }}
          pb={2}
        >
          <Button href={event.location}>
            {intl.formatMessage({ id: "navigate" })} &#8594;
          </Button>
        </Typography>
      ) : (
        ""
      )}
      <InfoIcon
        style={{ display: showFullContent ? "none" : "inline-block" }}
      />
    </Grid>
  );
}

const DatePickerTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#FFFFFF",
    },
    "&:hover fieldset": {
      borderColor: darkTheme.palette.primary.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: darkTheme.palette.primary.main,
    },
  },
});

function Events() {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [page, setPage] = useState(1);
  const handleClickCategory = function (category: number) {
    setSelectedCategory(category);
  };
  const [events, setEvents] = useState<Array<EventType>>([]);
  const intl = useIntl();
  const [search, setSearch] = useState("");
  const [fromDateValue, setFromDateValue] = React.useState<Date | null>(new Date(new Date().setHours(0, 0, 0, 0)));
  
  const [toDateValue, setToDateValue] = React.useState<Date | null>(null);
  const [ fromDateOpen, setFromDateOpen ] = React.useState(false);
  const [ toDateOpen, setToDateOpen ] = React.useState(false);

  const { loading, fetchMore } = useQuery(TAHKOCOM_EVENTS, {
    variables: {
      langId: intl.locale,
      page: page,
    },
    onCompleted: (data) => {
      setEvents([...events, ...data.www_tahkoComEvents.events]);

      if (
        data.www_tahkoComEvents.events.length > 0
      ) {
        setIsLoadingMore(true);
        setPage(page + 1);
      } else {
        setIsLoadingMore(false);
      }
    },
  });

  const handleSearchChange = (event: any) => {
    if (event.target.value !== "") {
      setSelectedCategory(0);
    }
    setSearch(event.target.value);
  };

  useEffect(() => {
    fetchMore({
      variables: {
        langId: intl.locale,
        page: page,
      },
    });
  }, []);

  return (
    <div
      style={{
        backgroundColor: theme.palette.primary.main,
        height: "100%",
        minHeight: "100vh",
      }}
    >
      {loading && page === 1 ? (
        <div
          style={{ display: "flex", justifyContent: "center", height: "100vh" }}
        >
          <CircularProgress style={{ color: "white" }} />
        </div>
      ) : (
        <Grid container>
          <Grid
            style={{ ...eventgrid, margin: 0 }}
            item
            xs={12}
            sm={12}
            md={12}
          >
            <LinkButton
              selected={selectedCategory === 0}
              onClick={() => handleClickCategory(0)}
            >
              {intl.formatMessage({ id: "all" })}
            </LinkButton>
            <LinkButton
              selected={selectedCategory === 582}
              onClick={() => handleClickCategory(582)}
            >
              {intl.formatMessage({ id: "entertainment" })}
            </LinkButton>
            <LinkButton
              selected={selectedCategory === 592}
              onClick={() => handleClickCategory(592)}
            >
              {intl.formatMessage({ id: "kidsandfamily" })}
            </LinkButton>
            <LinkButton
              selected={selectedCategory === 594}
              onClick={() => handleClickCategory(594)}
            >
              {intl.formatMessage({ id: "inslope" })}
            </LinkButton>
          </Grid>
          <ThemeProvider theme={darkTheme}>
            <Grid style={{ ...eventgrid, margin: 0, paddingTop: 0 }} xs={6}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={fiLocale}
              >
                <MobileDatePicker
                  disableMaskedInput
                  open={fromDateOpen}
                  label="&#8594;"
                  minDate={new Date()}
                  maxDate={toDateValue ? toDateValue : null}
                  value={fromDateValue}
                  onChange={(newValue) => {
                    setFromDateValue(newValue);
                    setFromDateOpen(false);
                  }}
                  renderInput={(params) => (
                    <DatePickerTextField
                      size="small"
                      {...params}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EventIcon onClick={() => setFromDateOpen(fromDateOpen ? false : true)}/>
                            {fromDateValue ? (
                              <ClearIcon onClick={() => setFromDateValue(new Date(new Date().setHours(0, 0, 0, 0)))} />
                            ) : (
                              ""
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid style={{ ...eventgrid, margin: 0, paddingTop: 0 }} xs={6}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={fiLocale}
              >
                <MobileDatePicker
                  disableMaskedInput
                  open={toDateOpen}
                  label="&#8592;"
                  minDate={fromDateValue ? fromDateValue : new Date()}
                  value={toDateValue}
                  onChange={(newValue) => {
                    setToDateValue(newValue);
                    setToDateOpen(false);
                  }}
                  renderInput={(params) => (
                    <DatePickerTextField
                      size="small"
                      {...params}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EventIcon onClick={() => setToDateOpen(toDateOpen ? false : true)}/>
                            {toDateValue ? (
                              <ClearIcon onClick={() => setToDateValue(null)} />
                            ) : (
                              ""
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </ThemeProvider>
          <Grid
            style={{ ...eventgrid, margin: 0, paddingTop: 0 }}
            item
            xs={12}
            sm={12}
            md={12}
          >
            <TextField
              size="small"
              id="outlined-basic"
              onChange={handleSearchChange}
              label={intl.formatMessage({ id: "search" })}
              variant="filled"
              InputLabelProps={{ style: { color: "#fff" } }}
            />
          </Grid>
          {loading ? (
            <Box sx={{ width: "100%" }}>
              <LinearProgress style={{ transition: "none" }} />
            </Box>
          ) : (
            ""
          )}
          {events
            .filter(
              (event) =>
                (selectedCategory === 0 && event.categories.length === 0) ||
                event.categories.find(
                  (category) =>
                    selectedCategory === 0 ||
                    category.parentId === selectedCategory ||
                    category.id === selectedCategory
                )
            )
            .sort(
              (a: any, b: any) =>
                new Date(a.dateStart).getTime() -
                new Date(b.dateStart).getTime()
            )
            .filter((event) =>
              fromDateValue
                ? new Date(event.dateStart).getTime() >
                    fromDateValue.getTime() &&
                  (toDateValue
                    ? new Date(event.dateStart).getTime() <
                      toDateValue.getTime()
                    : true)
                : toDateValue
                ? new Date(event.dateStart).getTime() < toDateValue.getTime()
                : true
            )
            .filter(
              (event) =>
                search === "" ||
                event.title.toLowerCase().includes(search.toLowerCase())
            )
            .map((event: EventType, index: number) => (
              <Event event={event} key={index} />
            ))}
        </Grid>
      )}
    </div>
  );
}

export default Events;
