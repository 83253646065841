

export function parseTimeString(timeString: string) {
    return timeString ? new Date(new Date().setHours(parseInt(timeString.split(':')[0]), parseInt(timeString.split(':')[1]?.substring(0, 2)))) : null;
}
export function occursWithinHour(opentime: Date | null): boolean {
    return opentime ? (new Date().getTime() > (opentime?.getTime() - 1000 * 60 * 60) && (new Date().getTime() < opentime?.getTime())) : false
}

export function isOpen(opentime: Date | null, closetime: Date | null): boolean {
    if (opentime && closetime) {
        // IS OPEN
        return (new Date().getTime() > opentime?.getTime() && (new Date().getTime() < closetime?.getTime())) ||
            // CLOCKAROUND
            (opentime?.getTime() > closetime?.getTime() && (new Date().getTime() < closetime?.getTime() || new Date().getTime() > opentime?.getTime()))
    } else {
        return false;
    }

}

export function isSeason(season: "summer" | "winter") {
    const month = new Date().getMonth() + 1;

    if (month >= 5 && month <= 10) {
        if (season === "summer") return true;
    } else {
        if (season === "winter") return true;
    }

    return false;
};