import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { theme } from "../../theme";
import {
  Badge,
  BadgeProps,
  Box,
  Chip,
  styled,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useIntl } from "react-intl";

const tablecontainer = {
  backgroundColor: theme.palette.primary.dark,
  borderRadius: 1,
  color: "#FFFFFF",
  marginBottom: "10px",
};

const tableheadertext = {
  color: "#FFFFFF",
};

const tableheader = {
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
};

const tablerow = {
  color: "#FFFFFF",
  borderBottom: 0,
  borderTop: "1px solid #FFFFFF",
  padding: theme.spacing(0.5),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
};

const inforow = {
  color: "#FFFFFF",
  borderTop: 0,
  borderBottom: 0,
  padding: theme.spacing(0.5),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(2),
};

interface TableProps {
  rows: Array<string[]> | null;
  headers: string[];
}

interface TableRowProps {
  row: string[];
  headers: string[];
}

interface SlopeBadgeProps extends BadgeProps {
  grade?: string;
}

const SlopeBadge = styled(Badge, {
  shouldForwardProp: (prop) => prop !== "grade",
})<SlopeBadgeProps>(({ grade, theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor:
      (grade === "pun"
        ? "#FF0000"
        : grade === "sin"
        ? "#0000FF"
        : grade === "mus"
        ? "#000000"
        : theme.palette.primary.main) + " !important",
  },
}));

function CustomTableRow({ headers, row }: TableRowProps) {
  const intl = useIntl();

  const [info, setInfo] = useState("");

  const handleInfo = function (value: string) {
    if (info !== "") {
      setInfo("");
    } else {
      setInfo(value);
    }
  };

  return (
    <>
      <TableRow sx={{ "&td:first-of-type, &th:first-of-type": { border: 0 } }}>
        {row
          .filter((value: string, index: number) => headers[index] !== "Color")
          .map((value: string, index: number) => (
            <TableCell
              size="small"
              padding="none"
              key={index}
              align="left"
              style={tablerow}
            >
              {headers[index] === "Status" ? (
                value === "Avoinna" ? (
                  <Chip
                    size="small"
                    label={intl.formatMessage({ id: "open" })}
                    color="success"
                  />
                ) : value === "Suljettu" ? (
                  <Chip
                    size="small"
                    label={intl.formatMessage({ id: "closed" })}
                    color="error"
                  />
                ) : value === "openwithinhour" ? (
                  <Chip
                    size="small"
                    label={intl.formatMessage({ id: "openwithinhour" })}
                    color="warning"
                  />
                ) : value === "closedwithinhour" ? (
                  <Chip
                    size="small"
                    label={intl.formatMessage({ id: "closedwithinhour" })}
                    color="warning"
                  />
                ) : (
                  value
                )
              ) : headers[index] === "Info" ? (
                value !== "" ? (
                  <InfoIcon onClick={() => handleInfo(value)} />
                ) : null
              ) : headers[index] === "Num" ? (
                <SlopeBadge
                  badgeContent={value}
                  grade={row[headers.findIndex((value) => value === "Color")]}
                  sx={{ marginRight: 2, whiteSpace: "nowrap" }}
                >
                  <div
                    style={{ width: theme.spacing(2.25), display: "block" }}
                  />
                </SlopeBadge>
              ) : (
                value
              )}
            </TableCell>
          ))}
      </TableRow>
      {info ? (
        <TableRow>
          <TableCell style={inforow} colSpan={headers.length}>
            <b>{info}</b>
          </TableCell>
        </TableRow>
      ) : (
        ""
      )}
    </>
  );
}

export function TableComponent({ headers, rows }: TableProps) {
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        height: "100%",
        minHeight: "100vh",
      }}
    >
      <TableContainer style={tablecontainer} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {headers
                .filter((header) => header !== "Color")
                .map((header: string, index: number) => (
                  <TableCell
                    style={tableheader}
                    size="small"
                    padding="none"
                    key={index}
                  >
                    {header !== "Num" &&
                    header !== "Info" &&
                    header !== "Status" ? (
                      <Typography style={tableheadertext} variant="h6">
                        {header}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row: string[], index: number) => (
              <CustomTableRow key={index} row={row} headers={headers} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
