import React from "react";
import { styled } from "@mui/system";
import { ButtonProps, Button } from "@mui/material";

interface LinkButtonProps extends ButtonProps {
  selected?: boolean;
}

const LinkButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "selected",
})<LinkButtonProps>(({ selected, theme }) => ({
  ...(selected
    ? {
        backgroundColor: "white !important",
        color: theme.palette.primary.dark,
      }
    : {
        color: "white",
      }),
}));

export default LinkButton;
