import { Box, Grid } from "@mui/material";
import React, { ReactElement, useMemo, useState, useEffect} from "react";
import ActivityCardDisplay from "./ActivityCardDisplay";

import tahko from "./Tahko-liehu.png";
import WeatherIconWinter from "./tahko-icon-saa-talvi.svg";
import WeatherIconSummer from "./tahko-icon-saa-kesa.svg";
import snow from "./tahko-icon-lumimaara.svg";
import slopes from "./tahko-icon-avoimet-rinteet.svg";
import lifts from "./tahko-icon-hisseja-avoinna.svg";
import skiier from "./tahko-icon-latuja.svg";
import snowmobile from "./tahko-icon-moottorikelkkareitit.svg";
import parking from "./tahko-icon-jaaparkki-ikoni.svg";
import snowing from "./tahko-icon-lumetus.svg";
import zipline from "./tahko-icon-zipline.svg";
import GolfCourse from "./tahko-icon-golf.svg";
import stairs from "./tahko-icon-vaellus.svg";
import bicycle from "./tahko-icon-pyoraily.svg";
import frisbeegolf from "./tahko-icon-frisbee.svg";
import hiking from "./tahko-icon-vaellus.svg";
import kayaking from "./tahko-icon-vedessa.svg";
import { theme } from "../../theme";
import { useQuery } from "@apollo/client";
import { ACTIVITY_INFO } from "../../api/api";
import { useIntl } from "react-intl";

import { isSeason } from "../../utils";
import { he } from "date-fns/locale";

type ActivityInfo = {
  snow: string;
  golf_old: string;
  golf_new: string;
  iceparking: string;
  icewarning: string;
  snowing: string;
  temperature: string;
  temperature_updated: string;
  slopesOpen: number;
  slopeCount: number;
  liftsOpen: number;
  liftCount: number;
  skitracks: number;
  skitracks_open: number;
  snowmobileRouteLength: number;
};

const imageStyle = {
  height: "160px",
  marginLeft: "-30px",
}

const portraitGridStyle = {
  height: "20vh",
  h1: {fontSize: "1.5rem"},
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}




function ActivityListDisplay(): ReactElement {
  const [activityInfo, setActivityInfo] = useState<ActivityInfo | null>();
  const intl = useIntl();
  const [orientation, setOrientation] = useState("landscape");

  function handleResize() {
    const windowDimensions = getWindowDimensions();
    windowDimensions.height > windowDimensions.width ? setOrientation("portrait") : setOrientation("landscape");
  }

  useEffect(() => {
    handleResize();
  }, []);
  window.addEventListener('resize', handleResize); 

  console.log("orientation", orientation);

  useQuery(ACTIVITY_INFO, {
    onCompleted: (data) => {
      setActivityInfo(data.www_activityInfo);
    },
  });

  const linkUrl = (url: string) => {
    return `https://api.tahko.com/tahkofy.php?url=${url}&client=TAHKOAPP`;
  };



  const winterActivities = useMemo(() => {
    return (
      <>
        <Grid style={orientation === "portrait" ? portraitGridStyle : {}} item xs={6} sm={orientation === "landscape" ? 4 : 6}>
          <ActivityCardDisplay
            title={intl.formatMessage({ id: "snowDepth" })}
            metric={
              activityInfo
                ? `${Math.round(parseFloat(activityInfo?.snow || ""))}CM`
                : undefined
            }
            icon={(<img src={snow} alt="snowDepth" style={imageStyle} />)}
          />
        </Grid>
        <Grid style={orientation === "portrait" ? portraitGridStyle : {}} item xs={6} sm={orientation === "landscape" ? 4 : 6}>
          <ActivityCardDisplay
            title={intl.formatMessage({ id: "slopeInfo" })}
            metric={
              activityInfo
                ? `${activityInfo?.slopesOpen}/${activityInfo?.slopeCount}`
                : undefined
            }
            link="/slopes"
            icon={(<img src={slopes} alt="slopeInfo" style={imageStyle} />)}
          />
        </Grid>
        <Grid style={orientation === "portrait" ? portraitGridStyle : {}} item xs={6} sm={orientation === "landscape" ? 4 : 6}>
          <ActivityCardDisplay
            title={intl.formatMessage({ id: "lifts" })}
            metric={
              activityInfo
                ? `${activityInfo?.liftsOpen}/${activityInfo?.liftCount}`
                : undefined
            }
            link="/lifts"
            icon={(<img src={lifts} alt="lifts" style={imageStyle} />)}
          />
        </Grid>
        <Grid style={orientation === "portrait" ? portraitGridStyle : {}} item xs={6} sm={orientation === "landscape" ? 4 : 6}>
          <ActivityCardDisplay
            title={intl.formatMessage({ id: "skiTracks" })}
            metric={activityInfo ? `${activityInfo?.skitracks_open}km` : undefined}
            link={linkUrl(
              "https://www.tahko.com/nae-ja-koe/aktiviteetit/maastohiihto%23latukartta"
            )}
            icon={(<img src={skiier} alt="skiTracks" style={imageStyle} />)}
          />
        </Grid>
        <Grid style={orientation === "portrait" ? portraitGridStyle : {}} item xs={6} sm={orientation === "landscape" ? 4 : 6}>
          <ActivityCardDisplay
            title={intl.formatMessage({ id: "snowmobileRoutes" })}
            metric={
              activityInfo
                ? `${activityInfo?.snowmobileRouteLength}km`
                : undefined
            }
            link={linkUrl(
              "https://www.tahko.com/nae-ja-koe/aktiviteetit/moottorikelkkailu%23kelkkareitit"
            )}
            icon={(<img src={snowmobile} alt="snowmobileRoutes" style={imageStyle} />)}
          />
        </Grid>
        <Grid style={orientation === "portrait" ? portraitGridStyle : {}} item xs={6} sm={orientation === "landscape" ? 4 : 6}>
          <ActivityCardDisplay
            title={intl.formatMessage({ id: "iceParking" })}
            metric={
              activityInfo
                ? activityInfo.iceparking === "Auki"
                  ? intl.formatMessage({ id: "yes" })
                  : intl.formatMessage({ id: "no" })
                : undefined
            }
            icon={(<img src={parking} alt="iceParking" style={imageStyle} />)}
          />
        </Grid>
        <Grid style={orientation === "portrait" ? portraitGridStyle : {}} item xs={6} sm={orientation === "landscape" ? 4 : 6}>
          <ActivityCardDisplay
            title={intl.formatMessage({ id: "snowmaking" })}
            metric={
              activityInfo
                ? activityInfo.snowing === "1"
                  ? intl.formatMessage({ id: "yes" })
                  : intl.formatMessage({ id: "no" })
                : undefined
            }
            icon={(<img src={snowing} alt="snowmaking" style={imageStyle} />)}
          />
        </Grid>
      </>
    );
  }, [activityInfo, intl, orientation]);

  const summerActivities = useMemo(() => {
    return (
      <>
        <Grid style={orientation === "portrait" ? portraitGridStyle : {}} item xs={6} sm={orientation === "landscape" ? 4 : 6}>
          <ActivityCardDisplay
            title={intl.formatMessage({ id: "golfCourses" })}
            metric={
              activityInfo
                ? `${
                    parseInt(activityInfo?.golf_new) +
                    parseInt(activityInfo?.golf_old)
                  }/36`
                : undefined
            }
            unit={intl.formatMessage({ id: "golfFairways" })}
            icon={<img src={GolfCourse} alt="golfCourses" style={imageStyle} />}
          />
        </Grid>
        <Grid style={orientation === "portrait" ? portraitGridStyle : {}} item xs={6} sm={orientation === "landscape" ? 4 : 6}>
          <ActivityCardDisplay
            title={intl.formatMessage({ id: "tahkoStairs" })}
            metric={`1054`}
            unit={intl.formatMessage({ id: "steps" })}
            link={
              intl.locale === "fi" ?
                linkUrl("https://www.tahko.com/paikka/tahkon-portaat/") :
                linkUrl("https://www.tahko.com/en/place/tahko-stairs/")
            }
            icon={<img src={stairs} alt="tahkoStairs" style={imageStyle} />}
          />
        </Grid>
        <Grid style={orientation === "portrait" ? portraitGridStyle : {}} item xs={6} sm={orientation === "landscape" ? 4 : 6}>
          <ActivityCardDisplay
            title={intl.formatMessage({ id: "bikeRoutes" })}
            metric={" "}
            link={
              intl.locale === "fi" ?
                linkUrl("https://www.tahko.com/nae-ja-koe/aktiviteetit/maastopyoraily/") :
                linkUrl("https://www.tahko.com/en/things-to-do/biking/")
            }
            icon={<img src={bicycle} alt="bikeRoutes" style={imageStyle} />}
          />
        </Grid>
        <Grid style={orientation === "portrait" ? portraitGridStyle : {}} item xs={6} sm={orientation === "landscape" ? 4 : 6}>
          <ActivityCardDisplay
            title={intl.formatMessage({ id: "discGolf" })}
            metric={`27/27`}
            unit={intl.formatMessage({ id: "golfFairways" })}            
            link={
              intl.locale === "fi" ?
                linkUrl("https://www.tahko.com/nae-ja-koe/aktiviteetit/frisbeegolf/") :
                linkUrl("https://www.tahko.com/en/things-to-do/activities/disc-golf/")
            }
            icon={<img src={frisbeegolf} alt="discGolf" style={imageStyle} />}
          />
        </Grid>
        <Grid style={orientation === "portrait" ? portraitGridStyle : {}} item xs={6} sm={orientation === "landscape" ? 4 : 6}>
          <ActivityCardDisplay
            title={intl.formatMessage({ id: "hiking" })}
            metric={" "}
            link={
              intl.locale === "fi" ?
                linkUrl("https://www.tahko.com/nae-ja-koe/aktiviteetit/retkeily-ja-luontokohteet") :
                linkUrl("https://www.tahko.com/en/things-to-do/activities/hiking-trails-and-natural-attractions/")
            }
            icon={<img src={hiking} alt="hiking" style={imageStyle} />}
          />
        </Grid>
        <Grid style={orientation === "portrait" ? portraitGridStyle : {}} item xs={6} sm={orientation === "landscape" ? 4 : 6}>
          <ActivityCardDisplay
            title={intl.formatMessage({ id: "waterActivities" })}
            metric={" "}
            link={
              intl.locale === "fi" ?
                linkUrl("https://www.tahko.com/nae-ja-koe/vesilla/") :
                linkUrl("https://www.tahko.com/en/things-to-do/in-water/")
            }
            icon={<img src={kayaking} alt="waterActivities" style={imageStyle} />}
          />
        </Grid>
        <Grid style={orientation === "portrait" ? portraitGridStyle : {}} item xs={6} sm={orientation === "landscape" ? 4 : 6}>
          <ActivityCardDisplay
            title={intl.formatMessage({ id: "tahkoZipline" })}
            metric={"1000m"}
            link={
                "https://shop.tahko.com/products/L21715"
            }
            icon={<img src={zipline} alt="tahkoZipline" style={imageStyle} />}
          />
        </Grid>
      </>
    );
  }, [activityInfo, intl, orientation]);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        height: "100vh",
        padding: theme.spacing(2),
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6} sm={orientation === "landscape" ? 4 : 12}>
          <ActivityCardDisplay
            title={intl.formatMessage({ id: "lataa tahko app!" })}
            metric={" "}
            link="https://api2.tahko.com/livevideo/"
            icon={
              (<img alt="Lataa Tahko App" src={tahko} style={imageStyle} />)
            }
          />
        </Grid>

        <Grid style={orientation === "portrait" ? portraitGridStyle : {}} item xs={6} sm={orientation === "landscape" ? 4 : 6}>
          <ActivityCardDisplay
            title={intl.formatMessage({ id: "weather" })}
            metric={
              activityInfo
                ? `${Math.round(parseFloat(activityInfo?.temperature || ""))}°C`
                : undefined
            }
            link={
              intl.locale === "fi" ?
                "https://api.tahko.com/forecabox.php" :
                "https://api.tahko.com/forecabox.php?lang=en"
            }
            icon={
              isSeason("winter") ? (
                <img alt="Sää" src={WeatherIconWinter} style={imageStyle} />
              ) : (
                <img alt="Sää" src={WeatherIconSummer} style={imageStyle} />

              )
            }
          />
        </Grid>

        {isSeason("winter") && winterActivities}
        {isSeason("summer") && summerActivities}

    
      </Grid>
    </Box>
  );
}

export default ActivityListDisplay;
