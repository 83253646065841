import { SLOPES_AND_LIFTS } from "../../api/api";
import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { TableComponent } from "./TableComponent";
import { useIntl } from "react-intl";
import CircularProgress from "@mui/material/CircularProgress";
import { theme } from "../../theme";
import { parseTimeString, occursWithinHour, isOpen } from "../../utils";

function Lifts() {
  const [lifts, setLifts] = useState<Array<string[]> | null>([]);
  const intl = useIntl();

  const { loading } = useQuery(SLOPES_AND_LIFTS, {
    onCompleted: (data) => {
      setLifts(
        data.www_slopesandlifts.lifts
          .filter(
            (lifts: any) =>
              lifts.nimi !== "" &&
              lifts.num !== "#" &&
              lifts.num.replace("&nbsp;", "") !== "L"
          )
          .map((lifts: any) => {
            const opentime = parseTimeString(lifts.avataan);
            const isOpenWithInHour = occursWithinHour(opentime);

            const closetime = parseTimeString(lifts.suljetaan);
            const isClosedWithInHour = occursWithinHour(closetime);

            const open = isOpen(opentime, closetime);

            const liftStatusText =  isOpenWithInHour
            ? "openwithinhour"
            : isClosedWithInHour
            ? "closedwithinhour"
            : open ? intl.formatMessage({ id: "open" }) : intl.formatMessage({ id: "closed" });

            return [
              lifts.num.replace("&nbsp;", ""),
              lifts.nimi,
              liftStatusText,
              lifts.info_f +
                (lifts.avataan
                  ? " " +
                    intl.formatMessage({ id: "open" }) +
                    ": " +
                    lifts.avataan +
                    " - " +
                    lifts.suljetaan
                  : ""),
            ];
          })
      );
    },
  });

  const headers = ["Num", intl.formatMessage({ id: "lift" }), "Status", "Info"];
  return (
    <div style={{ backgroundColor: theme.palette.primary.main }}>
      {loading ? (
        <div
          style={{ display: "flex", justifyContent: "center", height: "100vh" }}
        >
          <CircularProgress style={{ color: "white" }} />
        </div>
      ) : (
        <TableComponent headers={headers} rows={lifts} />
      )}
    </div>
  );
}

export default Lifts;
