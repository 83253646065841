import { gql } from "@apollo/client";

export const ACTIVITY_INFO = gql`
  query activityInfo {
    www_activityInfo {
        snow
        golf_old
        golf_new
        iceparking
        icewarning
        snowing
        temperature
        temperature_updated
        slopeCount
        slopesOpen
        liftCount
        liftsOpen
        skitracks
        skitracks_open
        snowmobileRouteLength
    }
  }
`;

export const TAHKOCOM_EVENTS = gql`
  query tahkoComEvents($langId: String, $page: Int) {
    www_tahkoComEvents(langId: $langId, page: $page) {
      page
      totalPages
      events {
        title
        contentshort
        contentfull
        imagesrc
        dateStart
        dateEnd
        time
        price
        categories {id, title, parentId}
        webpage
        location
      }
    }
  }
`;

export const TAHKOCOM_COMPANIES = gql`
  query tahkoComCompanies($langId: String, $page: Int) {
    www_tahkoComCompanies(langId: $langId, page: $page) {
      title
      contentfull
      imagesrc
      contactInfo
      opening
      openToday
      isOpen
      categories {id, title, parentId}
      webpage
      location
    }
  }
`;

export const TAHKOCOM_TABLEBOOKING = gql`
  query tahkoComTableBooking($link: String) {
    www_tahkoComTableBooking(link: $link) {
      url
      merchantID
    }
  }
`;

export const SLOPES_AND_LIFTS = gql`
  query slopesandlifts {
    www_slopesandlifts {
      slopes {
        num
        nimi
        pituus
        info_f
        info_e
        info_r
        info_s
        info_d
        coords
        avataan
        suljetaan
        extra
        lumetus
        extra_c
        status
      }
      lifts {
        num
        nimi
        pituus
        info_f
        info_e
        info_r
        info_s
        info_d
        coords
        avataan
        suljetaan
        extra
        lumetus
        extra_c
        status
      }

    }
  }
`;
