import { SLOPES_AND_LIFTS } from "../../api/api";
import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { TableComponent } from "./TableComponent";
import { useIntl } from "react-intl";
import { theme } from "../../theme";
import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { isOpen, occursWithinHour, parseTimeString } from "../../utils";

function Slopes() {
  const [slopes, setSlopes] = useState<Array<string[]> | null>([]);
  const intl = useIntl();

  const { loading } = useQuery(SLOPES_AND_LIFTS, {
    onCompleted: (data) => {
      console.log(data.www_slopesandlifts);
      // HUOM!! OTETAAN VAIN 25 ENSIMMÄISTÄ RINNETTÄ
      setSlopes(
        data.www_slopesandlifts.slopes
          .filter(
            (slopes: any, index: any) =>
              slopes.nimi !== "" && slopes.num !== "#" && index < 25
          )
          .map((slopes: any) => {
            const opentime = parseTimeString(slopes.avataan);
            const isOpenWithInHour = occursWithinHour(opentime);

            const closetime = parseTimeString(slopes.suljetaan);
            const isClosedWithInHour = occursWithinHour(closetime);

            const open = isOpen(opentime, closetime);

            const liftStatusText =  isOpenWithInHour
            ? "openwithinhour"
            : isClosedWithInHour
            ? "closedwithinhour"
            : open ? intl.formatMessage({ id: "open" }) : intl.formatMessage({ id: "closed" });


            return [
              slopes.num,
              slopes.nimi,
              slopes.pituus,
              liftStatusText,
              slopes.info_f +
                (slopes.avataan
                  ? " " +
                    intl.formatMessage({ id: "open" }) +
                    ": " +
                    slopes.avataan +
                    " - " +
                    slopes.suljetaan
                  : ""),
              slopes.extra,
            ];
          })
      );
    },
  });

  const headers = [
    "Num",
    intl.formatMessage({ id: "slope" }),
    intl.formatMessage({ id: "lenghtheigth" }),
    "Status",
    "Info",
    "Color",
  ];
  const frontslopesheight = Math.round(window.innerWidth / (1470 / 1050));
  const westslopesheight = Math.round(window.innerWidth / (1630 / 800));

  return (
    <div style={{ backgroundColor: theme.palette.primary.main }}>
      {loading ? (
        <div
          style={{ display: "flex", justifyContent: "center", height: "100vh" }}
        >
          <CircularProgress style={{ color: "white" }} />
        </div>
      ) : (
        <>
          <TableComponent headers={headers} rows={slopes} />
          <Typography
            style={{ color: "white", textAlign: "center" }}
            variant="h5"
          >
            {intl.formatMessage({ id: "frontslopes" })}
          </Typography>
          <Typography style={{ color: "white", textAlign: "center" }}>
            Tahkolaaksontie 1
          </Typography>
          <iframe
            style={{ marginTop: "30px", marginBottom: "30px" }}
            scrolling="no"
            allowFullScreen
            width="100%"
            height={frontslopesheight}
            src="https://panoraama.com/tahko/rinnekartta/rinnekartta.phtml?lev=1618&lang=fin&list=0&prev=&va=talvi&ran=15"
          />
          <Typography
            style={{ color: "white", textAlign: "center" }}
            variant="h5"
          >
            {intl.formatMessage({ id: "westslopes" })}
          </Typography>
          <Typography style={{ color: "white", textAlign: "center" }}>
            Tahkomäentie 319
          </Typography>
          <iframe
            style={{ marginTop: "30px", marginBottom: "30px" }}
            scrolling="no"
            allowFullScreen
            width="100%"
            height={westslopesheight}
            src="https://panoraama.com/tahko/rinnekartta/rinnekartta_lumil.phtml?lev=1630&lang=fin&list=0&prev=&ran=7"
          />
        </>
      )}
    </div>
  );
}

export default Slopes;
