import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { Routes, Route, useLocation } from "react-router-dom";
import messages_fi from "./translations/fi.json";
import messages_en from "./translations/en.json";
import messages_ru from "./translations/ru.json";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import ActivityList from "./components/ActivityWidget/ActivityList";
import ActivityListDisplay from "./components/ActivityWidget/ActivityListDisplay";
import Slopes from "./components/SlopesAndLifts/Slopes";
import Lifts from "./components/SlopesAndLifts/Lifts";
import Events from "./components/Events/Events";
import Companies from "./components/Companies/Companies";

const messages: any = {
  fi: messages_fi,
  en: messages_en,
  ru: messages_ru,
};

function App() {
  const search = useLocation().search;
  const langId = new URLSearchParams(search).get("langId");

  const [language, setLanguage] = useState("fi");

  useEffect(() => {
    langId && Object.keys(messages).includes(langId) && setLanguage(langId);
  }, [langId]);

  return (
    <IntlProvider
      locale={language}
      messages={messages[language]}
      defaultLocale="fi"
    >
      <ThemeProvider theme={theme}>
        <div>
          <Routes>
            <Route path="/activities" element={<ActivityList />} />
            <Route path="/" element={<ActivityList />} />
            <Route path="/display" element={<ActivityListDisplay />} />
            <Route path="/slopes" element={<Slopes />} />
            <Route path="/lifts" element={<Lifts />} />
            <Route path="/events" element={<Events />} />
            <Route path="/companies" element={<Companies />} />
          </Routes>
        </div>
      </ThemeProvider>
    </IntlProvider>
  );
}

export default App;
