import { Box, Grid } from "@mui/material";
import React, { ReactElement, useMemo, useState } from "react";
import ActivityCard from "./ActivityCard";
import { theme } from "../../theme";
import { useQuery } from "@apollo/client";
import { ACTIVITY_INFO } from "../../api/api";
import { useIntl } from "react-intl";
import LiveTv from "./tahko-icon-live.svg";
import WeatherIconWinter from "./tahko-icon-saa-talvi.svg";
import WeatherIconSummer from "./tahko-icon-saa-kesa.svg";
import BuyLiftTickets from "./tahko-icon-osta-hissilippu.svg";
import SnowDepth from "./tahko-icon-lumimaara.svg";
import SlopesIcon from "./tahko-icon-avoimet-rinteet.svg";
import LiftIcon from "./tahko-icon-hisseja-avoinna.svg";
import SkiTrackIcon from "./tahko-icon-latuja.svg";
import SnowmobileIcon from "./tahko-icon-moottorikelkkareitit.svg";
import IceParkingIcon from "./tahko-icon-jaaparkki-ikoni.svg";
import SnowMaking from "./tahko-icon-lumetus.svg";
import BusIcon from "./tahko-icon-bussiyhteydet.svg";
import OpeningHours from "./tahko-icon-aukioloajat.svg";
import GolfCourse from "./tahko-icon-golf.svg";
import PedalBike from "./tahko-icon-pyoraily.svg";
import DiscGolf from "./tahko-icon-frisbee.svg";
import Zipline from "./tahko-icon-zipline.svg";
import Hiking from "./tahko-icon-nuotio.svg";
import Kayaking from "./tahko-icon-vedessa.svg";
import Tahkolainen from "./tahko-icon-tahkolainen.svg";
import Stairs from "./tahko-icon-vaellus.svg";


import { isSeason } from "../../utils";

type ActivityInfo = {
  snow: string;
  golf_old: string;
  golf_new: string;
  iceparking: string;
  icewarning: string;
  snowing: string;
  temperature: string;
  temperature_updated: string;
  slopesOpen: number;
  slopeCount: number;
  liftsOpen: number;
  liftCount: number;
  skitracks: number;
  skitracks_open: number;
  snowmobileRouteLength: number;
};


function ActivityList(): ReactElement {
  const [activityInfo, setActivityInfo] = useState<ActivityInfo | null>();
  const intl = useIntl();

  useQuery(ACTIVITY_INFO, {
    onCompleted: (data) => {
      setActivityInfo(data.www_activityInfo);
    },
  });

  const linkUrl = (url: string) => {
    return `https://api.tahko.com/tahkofy.php?url=${url}&client=TAHKOAPP`;
  };



  const winterActivities = useMemo(() => {
    return (
      <>
        <Grid item xs={6} sm={4} md={3}>
          <ActivityCard
            title={intl.formatMessage({ id: "buylifttickets" })}
            metric={" "}
            link={linkUrl("https://rentle.store/tahko/l/tahkonrinteet/shop")}
            icon={<img src={BuyLiftTickets} alt=""/>}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <ActivityCard
            title={intl.formatMessage({ id: "snowDepth" })}
            metric={
              activityInfo
                ? `${Math.round(parseFloat(activityInfo?.snow || ""))}CM`
                : undefined
            }
            icon={<img src={SnowDepth} alt=""/>}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <ActivityCard
            title={intl.formatMessage({ id: "slopeInfo" })}
            metric={
              activityInfo
                ? `${activityInfo?.slopesOpen}/${activityInfo?.slopeCount}`
                : undefined
            }
            link="/slopes"
            icon={<img src={SlopesIcon} alt=""/>}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <ActivityCard
            title={intl.formatMessage({ id: "lifts" })}
            metric={
              activityInfo
                ? `${activityInfo?.liftsOpen}/${activityInfo?.liftCount}`
                : undefined
            }
            link="/lifts"
            icon={<img src={LiftIcon} alt=""/>}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <ActivityCard
            title={intl.formatMessage({ id: "skiTracks" })}
            metric={activityInfo ? `${activityInfo?.skitracks_open}km` : undefined}
            link={linkUrl(
              "https://www.tahko.com/nae-ja-koe/aktiviteetit/maastohiihto%23latukartta"
            )}
            icon={<img src={SkiTrackIcon} alt=""/>}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <ActivityCard
            title={intl.formatMessage({ id: "snowmobileRoutes" })}
            metric={
              activityInfo
                ? `${activityInfo?.snowmobileRouteLength}km`
                : undefined
            }
            link={linkUrl(
              "https://www.tahko.com/nae-ja-koe/aktiviteetit/moottorikelkkailu%23kelkkareitit"
            )}
            icon={<img src={SnowmobileIcon} alt=""/>}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <ActivityCard
            title={intl.formatMessage({ id: "iceParking" })}
            metric={
              activityInfo
                ? activityInfo.iceparking === "Auki"
                  ? intl.formatMessage({ id: "yes" })
                  : intl.formatMessage({ id: "no" })
                : undefined
            }
            icon={<img src={IceParkingIcon} alt=""/>}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <ActivityCard
            title={intl.formatMessage({ id: "snowmaking" })}
            metric={
              activityInfo
                ? activityInfo.snowing === "1"
                  ? intl.formatMessage({ id: "yes" })
                  : intl.formatMessage({ id: "no" })
                : undefined
            }
            icon={<img src={SnowMaking} alt=""/>}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <ActivityCard
            title={intl.formatMessage({ id: "bus" })}
            metric={" "}
            link={
              intl.locale === "fi" ?
              "https://kuopio.digitransit.fi/haku?&lang=fi&address1=Kuopion%20matkakeskus,%20Kuopio&lon1=27.680268&lat1=62.897907&address2=Tahko,%20Kuopio&lon2=28.037458&lat2=63.291405" : 
              "https://kuopio.digitransit.fi/haku?&lang=en&address1=Kuopion%20matkakeskus,%20Kuopio&lon1=27.680268&lat1=62.897907&address2=Tahko,%20Kuopio&lon2=28.037458&lat2=63.291405"
              }
            icon={<img src={BusIcon} alt=""/>}
          />
        </Grid>
      </>
    );
  }, [activityInfo, intl]);

  const summerActivities = useMemo(() => {
    return (
      <>
        <Grid item xs={6} sm={4} md={3}>
          <ActivityCard
            title={intl.formatMessage({ id: "golfCourses" })}
            metric={
              activityInfo
                ? `${
                    parseInt(activityInfo?.golf_new) +
                    parseInt(activityInfo?.golf_old)
                  }/36`
                : undefined
            }
            unit={intl.formatMessage({ id: "golfFairways" })}
            link={
                intl.locale === "fi" ?
                  linkUrl("https://www.tahko.com/nae-ja-koe/aktiviteetit/golf/") :
                  linkUrl("https://www.tahko.com/en/things-to-do/activities/golf/")
            }
            icon={<img src={GolfCourse} alt=""/>}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <ActivityCard
            title={intl.formatMessage({ id: "tahkoStairs" })}
            metric={`1054`}
            unit={intl.formatMessage({ id: "steps" })}
            link={
              intl.locale === "fi" ?
                linkUrl("https://www.tahko.com/paikka/tahkon-portaat/") :
                linkUrl("https://www.tahko.com/en/place/tahko-stairs/")
            }
            icon={<img src={Stairs} alt=""/>}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <ActivityCard
            title={intl.formatMessage({ id: "bikeRoutes" })}
            metric={" "}
            link={
              intl.locale === "fi" ?
                linkUrl("https://www.tahko.com/nae-ja-koe/aktiviteetit/maastopyoraily/") :
                linkUrl("https://www.tahko.com/en/things-to-do/biking/")
            }
            icon={<img src={PedalBike} alt=""/>}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <ActivityCard
            title={intl.formatMessage({ id: "discGolf" })}
            metric={`27/27`}
            unit={intl.formatMessage({ id: "golfFairways" })}            
            link={
              intl.locale === "fi" ?
                linkUrl("https://www.tahko.com/nae-ja-koe/aktiviteetit/frisbeegolf/") :
                linkUrl("https://www.tahko.com/en/things-to-do/activities/disc-golf/")
            }
            icon={<img src={DiscGolf} alt=""/>}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <ActivityCard
            title={intl.formatMessage({ id: "tahkoZipline" })}
            metric={"1000m"}
            link={
                "https://mobile.shop.tahko.com/products/L21715"
            }
            icon={<img src={Zipline} alt=""/>}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <ActivityCard
            title={intl.formatMessage({ id: "hiking" })}
            metric={" "}
            link={
              intl.locale === "fi" ?
                linkUrl("https://www.tahko.com/nae-ja-koe/aktiviteetit/retkeily-ja-luontokohteet") :
                linkUrl("https://www.tahko.com/en/things-to-do/activities/hiking-trails-and-natural-attractions/")
            }
            icon={<img src={Hiking} alt=""/>}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <ActivityCard
            title={intl.formatMessage({ id: "waterActivities" })}
            metric={" "}
            link={
              intl.locale === "fi" ?
                linkUrl("https://www.tahko.com/nae-ja-koe/vesilla/") :
                linkUrl("https://www.tahko.com/en/things-to-do/in-water/")
            }
            icon={<img src={Kayaking} alt=""/>}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <ActivityCard
            title={intl.formatMessage({ id: "bus" })}
            metric={" "}
            link={
              intl.locale === "fi" ?
              "https://kuopio.digitransit.fi/haku?&lang=fi&address1=Kuopion%20matkakeskus,%20Kuopio&lon1=27.680268&lat1=62.897907&address2=Tahko,%20Kuopio&lon2=28.037458&lat2=63.291405" : 
              "https://kuopio.digitransit.fi/haku?&lang=en&address1=Kuopion%20matkakeskus,%20Kuopio&lon1=27.680268&lat1=62.897907&address2=Tahko,%20Kuopio&lon2=28.037458&lat2=63.291405"
              }
            icon={<img src={BusIcon} alt=""/>}
          />
        </Grid>
      </>
    );
  }, [activityInfo, intl]);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        height: "100vh",
        padding: theme.spacing(2),
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6} sm={4} md={3}>
          <ActivityCard
            title={intl.formatMessage({ id: "live" })}
            metric={" "}
            link="https://api2.tahko.com/livevideo/"
            icon={
              <img src={LiveTv} alt=""/>
            }
          />
        </Grid>

        <Grid item xs={6} sm={4} md={3}>
          <ActivityCard
            title={intl.formatMessage({ id: "weather" })}
            metric={
              activityInfo
                ? `${Math.round(parseFloat(activityInfo?.temperature || ""))}°C`
                : undefined
            }
            link={
              intl.locale === "fi" ?
                "https://api.tahko.com/forecabox.php" :
                "https://api.tahko.com/forecabox.php?lang=en"
            }
            icon={
              isSeason("winter") ? (
                <img src={WeatherIconWinter} alt=""/>
              ) : (
                <img src={WeatherIconSummer} alt=""/>
              )
            }
          />
        </Grid>

        {isSeason("winter") && winterActivities}
        {isSeason("summer") && summerActivities}

        <Grid item xs={6} sm={4} md={3}>
          <ActivityCard
            title={intl.formatMessage({ id: "openinghours" })}
            metric={" "}
            link={
              intl.locale === "fi" ?
                "/companies" :
                "/companies?langId=en"
            }
            icon={<img src={OpeningHours} alt=""/>}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <ActivityCard
            title={intl.formatMessage({ id: "tahkolainen" })}
            metric={" "}
            link="https://mobile.shop.tahko.com/tahkolainen/card"
            icon={<img src={Tahkolainen} alt=""/>}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default ActivityList;
