import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useIntl } from "react-intl";
import {
  CircularProgress,
  LinearProgress,
  Box,
  TextField,
  Divider,
} from "@mui/material";
import { decode } from "html-entities";
import { theme } from "../../theme";
import { TAHKOCOM_COMPANIES, TAHKOCOM_TABLEBOOKING } from "../../api/api";
import { Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import InfoIcon from "@mui/icons-material/Info";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LinkButton from "../LinkButton";
import Chip from "@mui/material/Chip";
import { parseTimeString, occursWithinHour, isOpen } from "../../utils";

const companygrid = {
  color: "white",
  textAlign: "center" as const,
  backgroundColor: theme.palette.primary.dark,
  margin: theme.spacing(2),
  padding: theme.spacing(2),
  borderRadius: theme.spacing(0.5),
};

const companyimage = {
  height: "150px",
};

type Category = {
  id: number;
  title: string;
  parentId: number;
};

type CompanyType = {
  id: number;
  title: string;
  contentfull: string;
  contactInfo: string[];
  opening: string[];
  openToday: string;
  webpage: string;
  imagesrc: string;
  categories: Category[];
  location: string;
  isOpen: boolean;
  closetime: Date | null;
  opentime: Date | null;
  isOpenWithInHour: boolean;
};

interface companyProps {
  company: CompanyType;
}

type CategoryMap = {
  name: string,
  id: {[key: string] : number}
}

const CategoriesMap = [
  {name: "all", id: {"en": 0, "fi": 0}},
  {name: "seeanddo", id: {"en": 695, "fi": 162}},
  {name: "eatanddrink", id: {"en": 763, "fi": 180}},
  {name: "accommodation", id: {"en": 685, "fi": 140}}
];

function Company({ company }: companyProps) {
  const intl = useIntl();
  const [showFullContent, setShowFullContent] = useState(false);
  const [tableBookingLink, setTableBookingLink] = useState<string | null>(null);

  const handleSetFullContent = () => {
    showFullContent ? setShowFullContent(false) : setShowFullContent(true);
  };

  useQuery(TAHKOCOM_TABLEBOOKING, {
    variables: {
      link: company.webpage,
    },
    onCompleted: (data) => {
      setTableBookingLink(data.www_tahkoComTableBooking.url);
    },
    skip:
      company.webpage &&
      company.categories.find(
        (category) => category.parentId === 180 || category.id === 180
      )
        ? false
        : true,
  });

  return (
    <Grid
      onClick={() => handleSetFullContent()}
      style={companygrid}
      item
      xs={12}
      sm={12}
      md={12}
    >
      { company.imagesrc ? (
        ""
        //<img style={companyimage} src={company.imagesrc} alt={company.title} />
      ) : (
        ""
      )}
      <Typography pb={2} variant="h5">
        {company.title}
      </Typography>
      {company.isOpen ? (
        <Chip
          label={intl.formatMessage({ id: "open" })}
          color="success"
          sx={{ marginBottom: theme.spacing(2) }}
        />
      ) : company.isOpenWithInHour ? (
        <Chip
          label={intl.formatMessage({ id: "openwithinhour" })}
          color="warning"
          sx={{ marginBottom: theme.spacing(2) }}
        />
      ) : (
        ""
      )}
      <Typography pb={2}>{company.openToday}</Typography>
      {showFullContent && company.opening && (
        <>
          <Divider variant="middle" />
          {company.opening.map((op) => (
            <Typography pb={2}>{op}</Typography>
          ))}
          <Divider />
        </>
      )}

      <Typography
        style={{ display: showFullContent ? "block" : "none" }}
        pb={2}
      >
        {decode(company.contentfull)}
      </Typography>
      {company.contactInfo?.map((contactrow, i) => {
        // TO CHECK IF ROW IS PHONE NUMBER
        if (/^\+?\d+$/.test(contactrow?.replace(/\s/g, ""))) {
          return (
            <Typography
              style={{ display: showFullContent ? "block" : "none" }}
              key={i}
              pb={2}
            >
              <Button href={"tel:" + contactrow}>{contactrow}</Button>
            </Typography>
          );
        } else {
          return (
            <Typography
              style={{ display: showFullContent ? "block" : "none" }}
              key={i}
              pb={1}
            >
              {contactrow}
            </Typography>
          );
        }
      })}
      {company.webpage ? (
        <Typography
          style={{ display: showFullContent ? "block" : "none" }}
          pb={2}
        >
          <Button href={company.webpage} endIcon={<ArrowForwardIcon />}>
            {intl.formatMessage({ id: "companywebsite" })}
          </Button>
        </Typography>
      ) : (
        ""
      )}
      {company.location ? (
        <Typography
          style={{ display: showFullContent ? "block" : "none" }}
          pb={2}
        >
          <Button href={company.location} endIcon={<LocationOnIcon />}>
            {intl.formatMessage({ id: "navigate" })}
          </Button>
        </Typography>
      ) : (
        ""
      )}
      {tableBookingLink ? (
        <Typography
          style={{ display: showFullContent ? "block" : "none" }}
          pb={2}
        >
          <Button href={tableBookingLink}>
            {intl.formatMessage({ id: "bookatable" })} &#8594;
          </Button>
        </Typography>
      ) : (
        ""
      )}
      <InfoIcon
        style={{ display: showFullContent ? "none" : "inline-block" }}
      />
    </Grid>
  );
}

function Companies() {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [filterClosed, setFilterClosed] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [page, setPage] = useState(1);
  const handleClickCategory = function (category: number) {
    setSelectedCategory(category);
  };
  const [companies, setCompanies] = useState<Array<CompanyType>>([]);
  const intl = useIntl();
  const [search, setSearch] = useState("");

  const { loading, fetchMore } = useQuery(TAHKOCOM_COMPANIES, {
    variables: {
      langId: intl.locale,
      page: page,
    },
    onCompleted: (data) => {
      const tahkoComCompanies = data.www_tahkoComCompanies.map(
        (company: any) => {
          if (!company.openToday) {
            return { ...company, isOpen: false };
          }
          // company.openToday
          // Tänään: 9:00 - 17:00
          const regexpOpenHours =
            /^.*?:\s*([0-9]+:[0-9]+)\s*-\s*([0-9]+:[0-9]+)/;
          const openHoursMatch = company.openToday.match(regexpOpenHours);

          const opentime = parseTimeString(openHoursMatch[1]);
          const closetime = parseTimeString(openHoursMatch[2]);

          const isOpenWithInHour = occursWithinHour(opentime);
          const open = isOpen(opentime, closetime);

          return {
            ...company,
            isOpen: open,
            opentime: opentime,
            closetime: closetime,
            isOpenWithInHour: isOpenWithInHour,
          };
        }
      );
      setCompanies([...companies, ...tahkoComCompanies]);
      if (data.www_tahkoComCompanies.length > 0) {
        setIsLoadingMore(true);
        setPage(page + 1);
      } else {
        setIsLoadingMore(false);
      }
    },
  });

  const handleSearchChange = (event: any) => {
    if (event.target.value !== "") {
      setSelectedCategory(0);
    }
    setSearch(event.target.value);
  };

  useEffect(() => {
    fetchMore({
      variables: {
        langId: intl.locale,
        page: page,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <div
      style={{
        backgroundColor: theme.palette.primary.main,
        height: "100%",
        minHeight: "100vh",
      }}
    >
      {loading && page === 1 ? (
        <div
          style={{ display: "flex", justifyContent: "center", height: "100vh" }}
        >
          <CircularProgress style={{ color: "white" }} />
        </div>
      ) : (
        <Grid container>
          <Grid
            style={{ ...companygrid, margin: 0 }}
            item
            xs={12}
            sm={12}
            md={12}
          >
            {CategoriesMap.map((Category: CategoryMap, index: number) => (
              <LinkButton
              key={index}
              selected={selectedCategory === (Category.id[intl.locale] ? Category.id[intl.locale] : Category.id["en"])}
              onClick={() => handleClickCategory(Category.id[intl.locale] ? Category.id[intl.locale] : Category.id["en"])}
              >
                {intl.formatMessage({ id: Category.name })}
              </LinkButton>
            ))}
          </Grid>
          <Grid
            style={{ ...companygrid, margin: 0, paddingTop: 0 }}
            item
            xs={12}
            sm={12}
            md={12}
          >
            <FormGroup>
              <TextField
                size="small"
                id="outlined-basic"
                onChange={handleSearchChange}
                label={intl.formatMessage({ id: "search" })}
                variant="filled"
                InputLabelProps={{ style: { color: "#fff" } }}
              />
              <FormControlLabel
                label={intl.formatMessage({ id: "onlyopen" })}
                control={
                  <Checkbox
                    style={{ color: "#fff" }}
                    checked={filterClosed}
                    onChange={() => setFilterClosed(!filterClosed)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
              />
            </FormGroup>
          </Grid>
          {isLoadingMore ? (
            <Box sx={{ width: "100%" }}>
              <LinearProgress style={{ transition: "none" }} />
            </Box>
          ) : (
            ""
          )}
          {companies
            .filter((company) =>
              company.categories.find(
                (category) =>
                  selectedCategory === 0 ||
                  category.parentId === selectedCategory ||
                  category.id === selectedCategory
              )
            )
            .filter(
              (company) =>
                search === "" ||
                company.title.toLowerCase().includes(search.toLowerCase())
            )
            .filter((company) => company.isOpen || !filterClosed)
            .sort((ca: CompanyType, cb: CompanyType) => {
              return (
                Number(cb.isOpen) - Number(ca.isOpen) ||
                Number(cb.openToday !== null) - Number(ca.openToday !== null) ||
                Number(cb.isOpenWithInHour) - Number(ca.isOpenWithInHour) ||
                Number(ca.opentime) - Number(cb.opentime)
              );
            })
            .map((company: CompanyType, index: number) => (
              <Company company={company} key={index} />
            ))}
        </Grid>
      )}
    </div>
  );
}

export default Companies;
